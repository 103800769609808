import * as React from 'react';
import { graphql } from 'gatsby';
import { useMobile } from '../../../utils/use-series';

import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CLabelList,
  CSectTitle,
  CSpSliderCard,
  CFixedImg,
  CSimpleCard,
  LBanquetIncs,
  LVenue,
  LWifi,
  LBanquetContact,
  CFadeSlider,
  CSpSliderCard03,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const isSp = useMobile();

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        type="ja"
        data={{
          title: {
            main: '大宴会場「鳳翔」',
            sub: '宴会棟 3F',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/grand_hall/kv.png',
              },
              imgSp: {
                src: '/assets/images/banquet/grand_hall/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '宴会・会議',
                path: '/banquet/',
              },
            ],
            current: {
              label: '大宴会場「鳳翔」',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mbExlarge l_sect">
        <LWrap>
          <h2 className="c_headingLv2">大宴会場「鳳翔」</h2>
          <CLabelList
            data={[
              'パーティー',
              '会議・コンベンション',
              '講演・セミナー',
              'イベント・展示',
              'お別れの会・法要',
              '同窓会',
            ]}
          />
            <CSpSliderCard03
              exClass="u_mb80"
              col={3}
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/grand_hall/img_grand_hall.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/grand_hall/img_grand_hall02.png',
                    alt: '',
                  },
                },
              ]}
            />
          <p className="c_sectLead u_mb30">
            横浜市内最大級の宴会場「鳳翔」は、立食で最大1,500名を収容。天井高6.2mの壮麗で格調高い会場は、4室に分割することも可能。
            <br />
            就任パーティー、賀詞交歓会、国際会議など大規模な式典や、懇談会、セミナーなど、幅広くご利用いただけます。
          </p>
          {!isSp ? (
            <table className="c_fixedTable">
              <thead>
                <tr>
                  <th rowSpan={2}>スタイル</th>
                  <th colSpan={4}>人数</th>
                  <th rowSpan={2}>広さ（㎡）</th>
                </tr>
                <tr>
                  <th>正餐</th>
                  <th>立食</th>
                  <th>スクール</th>
                  <th>シアター</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>全スパン</th>
                  <td>900</td>
                  <td>1,500</td>
                  <td>1,000</td>
                  <td>1,450</td>
                  <td>1,460</td>
                </tr>
                <tr>
                  <th>3/4</th>
                  <td>600</td>
                  <td>1,000</td>
                  <td>700</td>
                  <td>1,100</td>
                  <td>1,095</td>
                </tr>
                <tr>
                  <th>1/2</th>
                  <td>400</td>
                  <td>450</td>
                  <td>450</td>
                  <td>700</td>
                  <td>730</td>
                </tr>
                <tr>
                  <th>1/4</th>
                  <td>180</td>
                  <td>300</td>
                  <td>230</td>
                  <td>320</td>
                  <td>365</td>
                </tr>
                <tr>
                  <th>ホワイエ</th>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>590</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <>
              <table className="c_fixedTable">
                <thead>
                  <tr>
                    <th colSpan={3}>正餐</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>スタイル</th>
                    <th>人数</th>
                    <th>広さ（㎡）</th>
                  </tr>
                  <tr>
                    <td>全スパン</td>
                    <td>900</td>
                    <td>1,400</td>
                  </tr>
                  <tr>
                    <td>3/4</td>
                    <td>600</td>
                    <td>1,095</td>
                  </tr>
                  <tr>
                    <td>1/2</td>
                    <td>400</td>
                    <td>730</td>
                  </tr>
                  <tr>
                    <td>1/4</td>
                    <td>180</td>
                    <td>365</td>
                  </tr>
                  <tr>
                    <td>ホワイエ</td>
                    <td>-</td>
                    <td>590</td>
                  </tr>
                </tbody>
              </table>
              <table className="c_fixedTable">
                <thead>
                  <tr>
                    <th colSpan={3}>立食</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>スタイル</th>
                    <th>人数</th>
                    <th>広さ（㎡）</th>
                  </tr>
                  <tr>
                    <td>全スパン</td>
                    <td>1,500</td>
                    <td>1,460</td>
                  </tr>
                  <tr>
                    <td>3/4</td>
                    <td>1,000</td>
                    <td>1,095</td>
                  </tr>
                  <tr>
                    <td>1/2</td>
                    <td>450</td>
                    <td>730</td>
                  </tr>
                  <tr>
                    <td>1/4</td>
                    <td>300</td>
                    <td>365</td>
                  </tr>
                  <tr>
                    <td>ホワイエ</td>
                    <td>-</td>
                    <td>590</td>
                  </tr>
                </tbody>
              </table>
              <table className="c_fixedTable">
                <thead>
                  <tr>
                    <th colSpan={3}>スクール</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>スタイル</th>
                    <th>人数</th>
                    <th>広さ（㎡）</th>
                  </tr>
                  <tr>
                    <td>全スパン</td>
                    <td>1,000</td>
                    <td>1,460</td>
                  </tr>
                  <tr>
                    <td>3/4</td>
                    <td>700</td>
                    <td>1,095</td>
                  </tr>
                  <tr>
                    <td>1/2</td>
                    <td>450</td>
                    <td>730</td>
                  </tr>
                  <tr>
                    <td>1/4</td>
                    <td>230</td>
                    <td>365</td>
                  </tr>
                  <tr>
                    <td>ホワイエ</td>
                    <td>-</td>
                    <td>590</td>
                  </tr>
                </tbody>
              </table>
              <table className="c_fixedTable">
                <thead>
                  <tr>
                    <th colSpan={3}>シアター</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>スタイル</th>
                    <th>人数</th>
                    <th>広さ（㎡）</th>
                  </tr>
                  <tr>
                    <td>全スパン</td>
                    <td>1,450</td>
                    <td>1,460</td>
                  </tr>
                  <tr>
                    <td>3/4</td>
                    <td>1,100</td>
                    <td>1,095</td>
                  </tr>
                  <tr>
                    <td>1/2</td>
                    <td>700</td>
                    <td>730</td>
                  </tr>
                  <tr>
                    <td>1/4</td>
                    <td>320</td>
                    <td>365</td>
                  </tr>
                  <tr>
                    <td>ホワイエ</td>
                    <td>-</td>
                    <td>590</td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          <CFixedImg
            exClass="u_mbLarge"
            width={1040}
            img={{
              src: '/assets/images/banquet/grand_hall/img_grand_hall04.png',
              alt: '',
            }}
          />
          <CSectTitle
            title={{
              ja: 'レイアウト例',
              en: (
                <>
                  LAYOUT <br className="u_sp" />
                  EXAMPLE
                </>
              ),
            }}
          />
          <CSimpleCard
            data={[
              {
                img: {
                  src: '/assets/images/banquet/grand_hall/img_grand_hall06.png',
                  alt: '',
                },
                text: (
                  <>
                    （左から）
                    <br />
                    鳳翔A・B：スクール 450名
                    <br />
                    鳳翔C・D：立食ブッフェ 450名
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/banquet/grand_hall/img_grand_hall07.png',
                  alt: '',
                },
                text: (
                  <>
                    （左から）
                    <br />
                    鳳翔A：正餐 180名
                    <br />
                    鳳翔B：立食ブッフェ 300名
                    <br />
                    鳳翔C：スクール 230名
                    <br />
                    鳳翔D：シアター 320名
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <LBanquetIncs />
      <LVenue />
      <section className="u_mtMedium u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
      <LBanquetContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
